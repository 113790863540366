<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.异常申请')"
                 fixed
                 right-:text="$t('tbm.取消')"
                 @click-left="back"
                 @click-right="back">
    </van-nav-bar>
    <div style="padding-top:46px;margin-bottom:50px;">
      <!-- <div style="color: #4378BE;line-height:40px;padding-left:10px;">{{ $t('tbm.异常申请注意事项') }}</div> -->
      <van-form ref="forms">
        <van-field readonly
                   clickable
                   input-align="right"
                   name="calendar"
                   v-model="form.applyDate"
                   :label="$t('tbm.异常日期')"
                   placeholder="" />
        <van-field readonly
                   clickable
                   v-model="userName"
                   :label="$t('tbm.姓名')"
                   input-align="right"
                   placeholder="" />
        <van-field readonly
                   clickable
                   v-model="deptName"
                   :label="$t('tbm.所在部门')"
                   input-align="right"
                   placeholder="" />
        <van-field v-model="form.reason"
                   style="margin-top:10px;"
                   rows="3"
                   autosize
                   :label="$t('tbm.异常原因')"
                   type="textarea"
                   :placeholder="$t('tbm.请输入异常原因')"
                   required
                   :rules="[{ required: true, message: this.$t('tbm.异常原因不能为空'),trigger: 'change'}]" />
        <van-field v-model="form.applyUnusualList.length"
                   :label="$t('tbm.异常明细')"
                   style="margin-top:10px;"
                   class="overtimeInputClass"
                   readonly
                   required
                   input-align="right"
                   placeholder=""
                   @click-right-icon="toAdd({})"
                   :rules="[{ required: true, message: this.$t('tbm.异常明细不能为空'),trigger: 'change' }]">
          <template #right-icon>
            <!-- <img src="@/views/business/images/add.png"
                 alt=""> -->
            <van-button size="small"
                        style="background-color:rgba(67, 120, 190, 1);border-radius:25px;border-color:rgba(67, 120, 190, 1);"
                        type="info">{{ $t('tbm.选择异常') }}</van-button>
          </template>
        </van-field>

        <div class="overtimeDetailList"
             v-if="flag">
          <div v-for="(item,index) in form.applyUnusualList"
               :key="index"
               v-show="form.applyUnusualList.length > 0">
            <div v-for="(items,key) in dataList"
                 :key="key">
              <label>{{items.label}}</label>
              <p v-if="items.text == 'dateDetail'">{{item[items.text].substring(0, 10)}}</p>
              <p v-else-if="items.text == 'pkWorkhourRange'">{{findTextMet(item[items.text],item.workTimes,item)}}</p>
              <p v-else-if="items.text == 'signFlag'">{{findTextMet(item[items.text],signFlagList,item)}}</p>
              <p v-else>{{findTextMet(item[items.text],unusualTypeList,item)}}</p>
            </div>
            <div class="overtimeButton">
              <!-- <p @click="toAdd({dataRow:item,rowIndex:index.toString()})">{{ $t('tbm.编辑') }}</p> -->
              <p @click="del(index)"
                 class="overtimeButton2">{{ $t('tbm.删除') }}</p>
            </div>
          </div>
          <div style="background:#eee;display:flex;"
               v-show="form.applyUnusualList.length == 0">
            <img src="@/views/business/images/empty.png"
                 alt=""
                 style="width:200px;height:150px;margin:20px auto;">
          </div>
        </div>
      </van-form>
    </div>
    <approvalButton ref="approvalButton"
                    v-if="form"
                    saveUrl="applyUnusual"
                    v-model="procConf.woCode"
                    :taskInstanceId="procConf.taskInstanceId"
                    :processName="procConf.processInstanceName"
                    :form="form"
                    :validForm="validateForm"
                    :submitVisable1="submitVisable1"></approvalButton>
  </div>
</template>
<script>
import approvalButton from '@/views/business/components/approvalButton';
export default {
  components: {
    approvalButton,
  },
  data () {
    return {
      flag: true,
      submitVisable1: false,
      form: {
        formNo: '',
        attachList: [],
        applyDate: '',
        reason: '',
        applyUnusualList: [],
        pkOrg: '',
        pkUser: '',
        pkDept: '',
        formStatus: '0',
        flag: '1'
      },
      procConf: {
        businessKey: '',
        processInstanceName: '异常申请',
        woCode: 'TbmapplyUnusual', //对应不同的请假类型
        taskInstanceId: '',
        variables: {}, //用于流程条件判断
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
      userName: '',
      deptName: '',
      deptAndPost: '',
      dataList: [
        {
          label: '异常日期',
          text: 'dateDetail'
        },
        {
          label: '区间',
          text: 'pkWorkhourRange'
        },
        // {
        //   label: '打卡时间',
        //   text: 'beginTime'
        // },
        {
          label: '上下班标记',
          text: 'signFlag'
        },
        {
          label: '异常类型',
          text: 'unusualType'
        },
      ],
      signFlagList: [
        {
          value: '0',
          text: '签到'
        }, {
          value: '1',
          text: '签退'
        }
      ],
      unusualTypeList: [
        { value: '05', text: '设备故障' },
        { value: '06', text: '交通管制' },
        { value: '07', text: '交通故障' },
        { value: '00', text: '忘记打卡' },
      ],
    }
  },
  methods: {
    back () {
      if (this.$route.query.type) {
        this.$router.go(-1);
      } else {
        this.$router.replace({
          name: 'tbm/applyUnusual/applyUnusualList'
        })
      }
    },
    findTextMet (value, data, items) {
      if (!value || !data || data.length == 0) {
        return value;
      } else {
        return data.find(item => item.value == value).text;
      }
    },
    del (index) {
      this.$dialog.confirm({
        title: '删除',
        message: this.$t('tbm.确认删除这条信息吗'),
      }).then(() => {
        this.form.applyUnusualList.splice(index, 1);
      }).catch(() => {
        // on cancel
      })
    },
    toAdd (query) {
      this.$router.push({
        // name: 'tbm/applyUnusual/applyUnusualDetail',
        name: 'tbm/applyUnusual/selectUnusualList',
        query: {
          ...this.$route.query,
          dataRow: {},
          rowIndex: null,
          ...query,
        },
        params: {
          ...this.$route.params,
          form: this.form
        }
      })
    },
    submitForm (resolve) {
      this.$refs.forms.validate().then(() => {
        let flag = true;
        if (this.form.applyUnusualList.length === 0) {
          this.$notify({ type: 'danger', message: this.$t('tbm.请添加异常信息') });
          flag = false;
        }
        let applyUnusual = this.form.applyUnusualList
        for (let i = 0; i < applyUnusual.length; i++) {
          if (applyUnusual[i].dateDetail > this.form.applyDate) {
            this.$notify({ type: 'danger', message: this.$t('tbm.异常日期不能大于申请日期') });
            flag = false;
          }
        }
        let dateDetails = '';
        this.form.applyUnusualList.forEach((item) => {
          dateDetails = dateDetails + item.dateDetail + ',';
          delete item.workTimes
        });
        //校验考勤周期是否封存或确认
        this.checkCycleSealedOrConfirm(dateDetails).then(resultCycleSealedOrConfirm => {
          if (resultCycleSealedOrConfirm.flag) {
            this.$notify({ type: 'danger', message: resultCycleSealedOrConfirm.msg });
            flag = false;
          }
        });

        //校验异常发生后必须两日内进行申请
        this.checkdateDetailsConfirm(dateDetails, this.form.applyDate).then(resultCheckdateDetails => {
          if (resultCheckdateDetails.flag) {
            this.$notify({ type: 'danger', message: resultCheckdateDetails.msg });
            flag = false;
          }
        });

        this.duplicateCheck().then(result => {
          if (result.data) {
            this.$notify({ type: 'danger', message: this.$t('tbm.不能重复申请') });
            flag = false;
          }
        });

        setTimeout(() => {
          resolve(flag);
        }, 1000)
      });
    },
    async validateForm () {
      let activitiData = {
        deptAndPost: '',
        customDeptCode: this.form.customDeptCode
      }
      sessionStorage.setItem('activitiData', JSON.stringify(activitiData))

      let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
      let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
      let officeoutReason = this.form.reason || '';
      if (officeoutReason.length > 5) {
        officeoutReason = officeoutReason.substring(0., 5) + '……'
      }
      this.procConf.processInstanceName = '异常申请' + '-' + deptName + ' ' + userName + '-' + officeoutReason + '-' + this.form.totalDays + '天'
      return new Promise((resolve, reject) => {
        this.submitForm(resolve);
      })
    },
    async checkCycleSealedOrConfirm (dateDetails) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/attendanceRegist/checkCycleSealedOrConfirm?startDate=' + dateDetails,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async checkdateDetailsConfirm (dateDetails, applyDate) {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyUnusual/checkdateDetailsConfirm?dateDetails=' + dateDetails + "&applyDate=" + applyDate,
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    //验证重复
    duplicateCheck () {
      return new Promise((resolve) => {
        this.$myHttp({
          method: 'POST',
          url: '/hrtbm/applyUnusual/checkRepeat',
          data: {
            'entity': this.form
          }
        }).then(res => {
          resolve(res.data);
        });
      });
    },
    async backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data.entity;
        if (backData) {
          let unusualList = backData.applyUnusualList
          if (this.$route.params.applyUnusualList) {
            unusualList = this.$route.params.applyUnusualList;
          }
          if (unusualList.length > 0) {
            Promise.all(unusualList.map(async (item, i) => {
              //耗时操作
              let obj = await this.getRangeNameListForEdit(unusualList[i])
              if (obj.data.ext.length > 0) {
                let workTimes = []
                obj.data.ext.map(data => {
                  let objList = {};
                  objList['value'] = data.id
                  objList['text'] = data.rangeName + '(' + data.startTime + '-' + data.endTime + ')'
                  objList['pkWorkhour'] = data.pkWorkhour
                  workTimes.push(objList)
                })
                backData.applyUnusualList[i]['workTimes'] = workTimes
              } else {
                backData.applyUnusualList[i].pkWorkhourRange = ''
              }
              this.flag = false;
              this.flag = true;
            }));
          }
        }
        this.form = backData;
        sessionStorage.setItem('formData', JSON.stringify(this.form));
        this.deptName = backData.deptName
        this.userName = backData.userName;
        this.procConf.businessKey = this.form.id;
        this.deptAndPost = parseInt(res.data.ext.deptAndPost)
      });
    },
    // 编辑 根据日期获取班时区间 构建option
    async getRangeNameListForEdit (row) {
      return this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/getRangeNameList',
        params: {
          'dateDetail': row.dateDetail.substr(0, 10)
        }
      })
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    // 获取默认组织信息
    this.deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
    this.userName = JSON.parse(localStorage.getItem('userInfo')).cname;
    this.form.pkDept = JSON.parse(localStorage.getItem('userInfo')).deptId;
    this.form.pkOrg = JSON.parse(localStorage.getItem('userInfo')).companyId;
    this.form.pkUser = JSON.parse(localStorage.getItem('userInfo')).id;
    let year = (new Date().getFullYear());
    let month = (new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1);
    let date = new Date().getDate() > 9 ? new Date().getDate() : '0' + new Date().getDate();
    // 生成编号
    var timestamp = new Date().getTime().toString().substring(6, 12);
    var formNo = 'YC' + year + timestamp;
    this.form.formNo = formNo;
    this.form.applyDate = year + '-' + month + '-' + date;

    if (JSON.parse(sessionStorage.getItem('formData'))) {
      this.submitVisable1 = true;
    }
    let form = this.$route.params.form || JSON.parse(sessionStorage.getItem('formData')) || null;
    sessionStorage.removeItem('formData');
    if (form) {
      this.form = form;
      let unusualList = form.applyUnusualList
      if (this.$route.params.applyUnusualList) {
        this.form.applyUnusualList = this.$route.params.applyUnusualList;
        unusualList = this.$route.params.applyUnusualList;
      }
      if (unusualList.length > 0) {
        Promise.all(unusualList.map(async (item, i) => {
          //耗时操作
          let obj = await this.getRangeNameListForEdit(unusualList[i])
          if (obj.data.ext.length > 0) {
            let workTimes = []
            obj.data.ext.map(data => {
              let objList = {};
              objList['value'] = data.id
              objList['text'] = data.rangeName + '(' + data.startTime + '-' + data.endTime + ')'
              objList['pkWorkhour'] = data.pkWorkhour
              workTimes.push(objList)
            })
            this.form.applyUnusualList[i]['workTimes'] = workTimes
          } else {
            this.form.applyUnusualList[i].pkWorkhourRange = ''
          }
          this.flag = false;
          this.flag = true;
        }));
      }
    } else if (this.form.id) {
      this.backFillEditData(true);
    };
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "approvalPanel") {
      next();
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },
}
</script>
<style scoped>
.overtimeDetailList {
  padding: 10px;
}
.overtimeDetailList > div {
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.overtimeDetailList > div > div {
  display: flex;
  margin: 10px 0;
}
.overtimeDetailList > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 80px;
}
.overtimeButton {
  display: flex;
  justify-content: center;
}
/* .overtimeButton p:nth-child(1) {
  margin-right: 20px;
  background-color: rgb(32, 172, 155);
  color: #fff;
} */
.overtimeButton .overtimeButton2 {
  border: 1px solid #f02d2d;
  color: #f02d2d;
}
.overtimeButton p {
  padding: 3px 15px;
  border-radius: 15px;
}
</style>
<style>
.overtimeInputClass input {
  color: #fff !important;
}
.overtimeInputClass .van-field__right-icon {
  width: 70px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>